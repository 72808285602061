import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

class MyCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialSlide: props.currentSlide,
            currentSlide: 0
        };

    }

   

    componentDidMount() {
        this.setState((state) => ({
            currentSlide: state.initialSlide,
        }));
    }

    render() {
        return (
            <Carousel
                autoPlay={true}
                selectedItem={this.state.currentSlide}
                stopOnHover={false} 
                infiniteLoop={true}
                showThumbs={false}
            >
                {this.props.children}
            </Carousel>
        );
    }
}

export default MyCarousel;
