import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../style/portfolio-singlepage.less";
import Img from "gatsby-image";
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import MyCarousel from "../components/my-carousel";


const getSelectedItem = (query) => {
    let item = 0;
    if (query) {
        let params = queryString.parse(query, {parseNumbers: true})
        if ('item' in params && Number.isInteger(params.item)) {
            item = params.item;
        }
    }
    return item;
};

export default function({ data }) {
    const location = useLocation();
    let item = (location.search && getSelectedItem(location.search)) || 0;
    if (item > data.markdownRemark.frontmatter.images.length || item < 0) {
        item = 0;
    }
    data.selectedItem = item;
    let items = [];
    data.markdownRemark.frontmatter.images.forEach(function(e, i) {
        items.push(<div key={i}>
            <Img fluid={
                        e.subimage.childImageSharp.fluid
                    }/>
            </div>);
    });
    return (
        <Layout >
            <SEO
                lang="en"
                title={data.markdownRemark.frontmatter.title}
                description={data.markdownRemark.frontmatter.description}
                
            />
            <div className="container">
                <article className="portfolio-post">
                <MyCarousel currentSlide={data.selectedItem}> 
                    {items}
                </MyCarousel>
                </article>
            </div>
        </Layout>
    );
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            htmlAst
            id
            frontmatter {
                title
                date
                description
                images {
                    subimage {
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 8000, quality:100) {
                                srcSet
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;